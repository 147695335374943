import React, { Component } from 'react';
import { Form, Input, Icon, Button } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router';
import { auth } from '../firebase';

class Login extends Component<RouteComponentProps> {
  state = {
    email: '',
    password: '',
  }
  onInputEmail = (val: string) => {
    this.setState({ email: val });
  };
  onInputPassword = (val: string) => {
    this.setState({ password: val });
  };
  onSubmit = async (evt: any) => {
    evt.preventDefault();
    const {
      email,
      password
    } = this.state;

    try {
      const user = await auth.signInWithEmailAndPassword(email, password);
      this.props.history.push('/');
    } catch (err) {
      alert(err);
    }

    return false;
  };

  render() {
    return (
      <div className="login-page">
        <Form className='login-form' onSubmit={this.onSubmit}>
          <Form.Item>
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email address"
              onInput={ evt => this.onInputEmail(evt.currentTarget.value) }
            />
          </Form.Item>
          <Form.Item>
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
              onInput={ evt => this.onInputPassword(evt.currentTarget.value) }
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Log in
          </Button>
        </Form>
      </div>
    );
  }
}

export default withRouter(Login);
