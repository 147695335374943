
export const firebaseConfig = {
  apiKey: 'AIzaSyBAzi-Z-adj6k-oUTPE8ZAlk_0BbBrgL8s',
  authDomain: 'wrap-8624c.firebaseapp.com',
  databaseURL: 'https://wrap-8624c.firebaseio.com',
  projectId: 'wrap-8624c',
  storageBucket: 'wrap-8624c.appspot.com',
  messagingSenderId: '129977558449',
  appId: '1:129977558449:web:5967f2700991aeec'
};
