import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router';

interface PrivateRouteProps extends RouteProps {
  component: any;
  authenticated: boolean;
}
const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  authenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => authenticated === true ? (
      <Component {...props} {...rest} />
    ): (
      <Redirect to='/login' />
    )}
  />
);

export default PrivateRoute;
