import React, { Component } from 'react';
import qrCodeIcon from '../assets/img/qr-code-icon.svg';
import defaultPhoto from '../assets/img/user.png';
import { decryptString } from '../crypt';
import { Button, Card, Spin } from 'antd';
import QRCodeScanner from '../components/QRCodeScanner';
import { db } from '../firebase';
import { UserContext } from '../App';

const CheckGuestStatusIntro = (props: any) => (
  <div className='check-guest-status__intro'>
    <img className='check-guest-status__icon' src={qrCodeIcon} alt='qr code' />
    <Button shape='round' type='primary' size='large' onClick={props.onDismiss} icon='scan'>Start Scanning</Button>
  </div>
);

interface CheckGuestStatusResultProps {
  qrCodeData: string;
  onDismiss: () => void;
}
interface CheckGuestStatusResultState {
  id: string;
  fullName: string;
  side: number;
  loading: boolean;
  checkedIn: boolean;
}
class CheckGuestStatusResult extends Component<CheckGuestStatusResultProps, CheckGuestStatusResultState> {
  state = {
    id: '',
    fullName: '',
    side: 1,
    loading: true,
    checkedIn: true,
  };

  async componentDidMount() {
    const data = decryptString(this.props.qrCodeData);
    const [id, fullName, side] = data.split('/');
    if (data && id) {
      this.setState({
        id,
        fullName,
        side: +side,
      });
  
      await this.fetchAttendeeData(id);
    } else {
      this.onInvalidCode();
    }

  }
  getRelationText(side: number) {
    if (side === 1) {
      return `Groom's side`;
    }
    if (side === 2) {
      return `Bride's side`;
    }
  }

  onSetCheckin = async (checkin: boolean) => {
    if (this.state.id) {
      await db.collection('attendees').doc(this.state.id).update({
        checkedIn: checkin
      });
      await this.fetchAttendeeData(this.state.id);
    }
  };

  onInvalidCode = () => {
    alert('The QR code is invalid');
    this.props.onDismiss();
  };

  fetchAttendeeData = async (id: string) => {
    this.setState({
      loading: true
    });

    const doc = await db.collection('attendees').doc(id).get();
    if (doc.exists) {
      const docData = doc.data();
      if (docData) {
        this.setState({ ...docData, fullName: `${docData.firstName} ${docData.lastName}` });
      }
    } else {
      this.onInvalidCode();
    }

    this.setState({
      loading: false
    });
  };

  render() {
    const {
      fullName,
      side,
      loading,
      checkedIn,
    } = this.state;
    return (
      <UserContext.Consumer>
        {(auth: any) => (
          <div className='check-guest-status-result-wrapper'>
            <Card className='check-guest-status-result__card'>
              <div className="check-guest-status-result__photo-wrapper">
                <img src={defaultPhoto} alt='user' />
              </div>
              <h2 className="check-guest-status-result__guest-name">{fullName}</h2>
              <p className="check-guest-status-result__relation">{this.getRelationText(side)}</p>
              {loading ?
                <Spin size="large" />
                :
                <div className="check-guest-status-result__actions">
                  <div>{ checkedIn ? 'Already checked in' : 'Not checked in.' }</div>
                  {
                    auth.role === 'checkin' &&
                    <Button
                      type='primary'
                      shape='round'
                      size='large'
                      icon='plus'
                      disabled={checkedIn}
                      onClick={() => this.onSetCheckin(true)}
                      block
                    >
                      Check in
                    </Button>
                  }
                  {
                    auth.role === 'checkout' &&
                    <Button
                      type='primary'
                      shape='round'
                      size='large'
                      icon='minus'
                      disabled={!checkedIn}
                      onClick={() => this.onSetCheckin(false)}
                      block
                    >
                      Check out
                    </Button>
                  }
                </div>
              }
            </Card>
            <Button shape='round' size='large' type='dashed' icon='arrow-left' onClick={this.props.onDismiss}>Back to Scanning</Button>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default class CheckGuestStatus extends Component {
  state = {
    showIntro: true,
    qrCodeData: null,
  };
  onToggleIntro = () => {
    this.setState({ showIntro: !this.state.showIntro });
  };
  onRetrieveQRCodeData = (data: string) => {
    this.setState({ qrCodeData: data });
  };
  onClearQRCodeData = () => {
    this.setState({ qrCodeData: null });
  };

  render() {

    const { showIntro, qrCodeData } = this.state;

    if (showIntro) {
      return <CheckGuestStatusIntro onDismiss={() => this.onToggleIntro()} />
    }
    if (qrCodeData) {
      return <CheckGuestStatusResult qrCodeData={qrCodeData} onDismiss={() => this.onClearQRCodeData()} />;
    }
    return (
      <div>
        <div className="check-guest-status__scanner-wrapper">
          <QRCodeScanner onScan={this.onRetrieveQRCodeData} />
        </div>
        <p>Hold the QR code in front of your camera.</p>
        <Button shape='round' size='large' type='danger' icon='close' onClick={() => this.onToggleIntro()}>Cancel</Button>
      </div>
    );
  }
}
