import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Layout, Menu, Tag } from 'antd';
import CheckGuestStatus from './pages/CheckGuestStatus';
import Login from './pages/Login';
import PrivateRoute from './components/PrivateRoute';
import './App.css';
import { auth } from './firebase';

const { Header, Content, Footer } = Layout;

export const UserContext = React.createContext({});

class App extends Component {
  state = {
    auth: {
      loading: true,
      authenticated: false,
      user: null,
      role: '',
    }
  };

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (user) {
        const hasCheckin = /checkin/.test(user.email || '');
        const hasCheckout = /checkout/.test(user.email || '');
        this.setState({
          auth: {
            loading: false,
            authenticated: true,
            user,
            role: (hasCheckin && 'checkin') || (hasCheckout && 'checkout') || '',
          }
        });
      } else {
        this.setState({
          auth: {
            loading: false,
            authenticated: false,
            user: null,
            role: '',
          }
        });
      }
    });
  }
  onLogout = () => {
    auth.signOut();
  };

  render() {

    const { loading, authenticated } = this.state.auth;

    if (loading) {
      return (
        <p>Loading...</p>
      );
    }

    return (
      <div className="App">
        <UserContext.Provider value={this.state.auth}>
          <Router>
            <Layout className="layout">
              <Header>
                <div className="logo" />
                <Menu
                  theme="dark"
                  mode="horizontal"
                  style={{ lineHeight: '64px' }}
                >
                  <Menu.Item key="1"><Link to="/">Home</Link></Menu.Item>
                  <Menu.Item key="login"><Link to="/login">Login</Link></Menu.Item>
                </Menu>
              </Header>
              <Content>
                <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
                  <PrivateRoute path="/" exact authenticated={authenticated} component={CheckGuestStatus} />
                  <Route path="/login" component={Login} />
                </div>
              </Content>
              <Footer style={{ textAlign: 'center' }}>
                wRAP ©2019 Created by XKojiMedia
                <div>
                  <Tag onClick={this.onLogout}>Logout</Tag>
                </div>
              </Footer>
            </Layout>
          </Router>
        </UserContext.Provider>
      </div>
    );
  }
}

export default App;
