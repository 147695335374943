import React, { Component } from 'react';
import QrReader from 'react-qr-reader';

interface QRCodeScannerProps {
  onScan: Function;
}
class QRCodeScanner extends Component<QRCodeScannerProps> {
  state = {
    result: 'No result'
  };

  handleScan = (data: any) => {
    if (data) {
      this.setState({
        result: data
      });
      this.props.onScan(data);
    }
  }
  handleError = (err: any) => {
    console.error(err)
  }
  render() {
    return (
      <div>
        <QrReader
          delay={300}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: '100%' }}
        />
        {/* <p>{this.state.result}</p> */}
      </div>
    );
  }
}

export default QRCodeScanner;
